import React, { useState, useEffect } from 'react';
import { supabase } from '../supabase/config';
import { Doughnut, Bar } from 'react-chartjs-2';
import { ChevronLeftIcon, ChevronRightIcon, ListBulletIcon, XMarkIcon } from '@heroicons/react/24/outline';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

// Generate colors dynamically
const generateColors = (count) => {
  const baseColors = [
    { h: 142, s: 76, l: 36 }, // green
    { h: 199, s: 89, l: 48 }, // blue
    { h: 262, s: 83, l: 58 }, // purple
    { h: 326, s: 81, l: 54 }, // pink
    { h: 31, s: 95, l: 44 },  // orange
    { h: 43, s: 96, l: 48 },  // yellow
    { h: 187, s: 75, l: 39 }, // teal
    { h: 245, s: 58, l: 51 }, // indigo
    { h: 0, s: 84, l: 60 },   // red
    { h: 161, s: 94, l: 43 }  // emerald
  ];

  return Array(count).fill(0).map((_, index) => {
    const baseColor = baseColors[index % baseColors.length];
    const variation = Math.floor(index / baseColors.length);
    const l = Math.max(30, Math.min(70, baseColor.l + (variation * 10)));
    return `hsl(${baseColor.h}, ${baseColor.s}%, ${l}%)`;
  });
};

function CategoryAnalysis({ currentTheme }) {
  const [view, setView] = useState('major');
  const [selectedMajor, setSelectedMajor] = useState(null);
  const [selectedMinor, setSelectedMinor] = useState(null);
  const [dateRange, setDateRange] = useState('all');
  const [customStartDate, setCustomStartDate] = useState('');
  const [customEndDate, setCustomEndDate] = useState('');
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [showTransactions, setShowTransactions] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [transactionsLoading, setTransactionsLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [transactionSort, setTransactionSort] = useState({ field: 'amount', direction: 'desc' });
  const [categorySort, setCategorySort] = useState({ field: 'amount', direction: 'desc' });
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedYear, setSelectedYear] = useState('all');
  const [years, setYears] = useState([]);
  const [topVendors, setTopVendors] = useState([]);

  useEffect(() => {
    fetchData();
    fetchTopVendors();
  }, [view, selectedMajor, selectedMinor, dateRange, customStartDate, customEndDate, categorySort, selectedYear]);

  const fetchData = async () => {
    try {
      setLoading(true);
      let startDate = null;
      let endDate = null;

      // Handle year and month filtering
      if (selectedYear !== 'all') {
        if (dateRange === 'all') {
          // If only year is selected, show full year
          startDate = `${selectedYear}-01-01`;
          endDate = `${selectedYear}-12-31`;
        } else if (dateRange === 'custom') {
          // Custom date range
          startDate = customStartDate;
          endDate = customEndDate;
        } else {
          // Month is selected (dateRange will be 1-12)
          const month = dateRange.padStart(2, '0');
          startDate = `${selectedYear}-${month}-01`;
          const lastDay = new Date(selectedYear, parseInt(dateRange), 0).getDate();
          endDate = `${selectedYear}-${month}-${lastDay}`;
        }
      } else if (dateRange === 'custom') {
        startDate = customStartDate;
        endDate = customEndDate;
      }

      let { data: spendData, error } = await supabase.rpc(
        view === 'major' ? 'get_major_category_spend' :
        view === 'minor' ? 'get_minor_category_spend' :
        'get_vendor_spend',
        {
          ...(view === 'minor' && { p_major_category: selectedMajor }),
          ...(view === 'vendor' && {
            p_major_category: selectedMajor,
            p_minor_category: selectedMinor
          }),
          ...(startDate && { start_date: startDate }),
          ...(endDate && { end_date: endDate })
        }
      );

      if (error) throw error;

      // Sort the data based on categorySort
      const sortedData = [...spendData].sort((a, b) => {
        const aValue = categorySort.field === 'name' 
          ? (view === 'major' ? a.major_category : view === 'minor' ? a.minor_category : a.vendor)
          : a.total_spend;
        const bValue = categorySort.field === 'name'
          ? (view === 'major' ? b.major_category : view === 'minor' ? b.minor_category : b.vendor)
          : b.total_spend;

        if (categorySort.field === 'name') {
          return categorySort.direction === 'asc' 
            ? aValue.localeCompare(bValue)
            : bValue.localeCompare(aValue);
        }
        return categorySort.direction === 'asc' 
          ? aValue - bValue 
          : bValue - aValue;
      });

      setData(sortedData);
    } catch (error) {
      console.error('Error fetching category data:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchTopVendors = async () => {
    try {
      let startDate = null;
      let endDate = null;

      // Handle year and month filtering (reuse the same logic from fetchData)
      if (selectedYear !== 'all') {
        if (dateRange === 'all') {
          startDate = `${selectedYear}-01-01`;
          endDate = `${selectedYear}-12-31`;
        } else if (dateRange === 'custom') {
          startDate = customStartDate;
          endDate = customEndDate;
        } else {
          const month = dateRange.padStart(2, '0');
          startDate = `${selectedYear}-${month}-01`;
          const lastDay = new Date(selectedYear, parseInt(dateRange), 0).getDate();
          endDate = `${selectedYear}-${month}-${lastDay}`;
        }
      } else if (dateRange === 'custom') {
        startDate = customStartDate;
        endDate = customEndDate;
      }

      const { data: vendorsData, error } = await supabase.rpc(
        'get_top_vendors',
        {
          p_major_category: view === 'major' ? selectedMajor : view === 'minor' || view === 'vendor' ? selectedMajor : null,
          p_minor_category: view === 'minor' ? selectedMinor : view === 'vendor' ? selectedMinor : null,
          start_date: startDate,
          end_date: endDate,
          p_limit: 10
        }
      );

      if (error) throw error;
      setTopVendors(vendorsData);
    } catch (error) {
      console.error('Error fetching top vendors:', error);
    }
  };

  const handleDrillDown = (item) => {
    if (view === 'major') {
      setSelectedMajor(item.major_category);
      setView('minor');
      setBreadcrumbs([...breadcrumbs, { label: item.major_category, view: 'major' }]);
    } else if (view === 'minor') {
      setSelectedMinor(item.minor_category);
      setView('vendor');
      setBreadcrumbs([...breadcrumbs, { label: item.minor_category, view: 'minor' }]);
    }
  };

  const handleBreadcrumbClick = (index) => {
    const newBreadcrumbs = breadcrumbs.slice(0, index);
    setBreadcrumbs(newBreadcrumbs);

    if (index === 0) {
      setView('major');
      setSelectedMajor(null);
      setSelectedMinor(null);
    } else {
      setView('minor');
      setSelectedMinor(null);
    }
  };

  const fetchTransactions = async (item) => {
    try {
      setTransactionsLoading(true);
      let startDate = null;
      let endDate = null;

      // Calculate date range (reuse the same logic from fetchData)
      if (dateRange !== 'all') {
        const today = new Date();
        endDate = today.toISOString().split('T')[0];
        
        if (dateRange === 'custom') {
          startDate = customStartDate;
          endDate = customEndDate;
        } else {
          const days = {
            '30d': 30,
            '90d': 90,
            '180d': 180,
            '1y': 365
          }[dateRange];
          
          startDate = new Date(today.setDate(today.getDate() - days))
            .toISOString().split('T')[0];
        }
      }

      const { data: transactionData, error } = await supabase.rpc(
        'get_transactions_by_category',
        {
          p_major_category: view === 'major' ? item.major_category : selectedMajor,
          p_minor_category: view === 'minor' ? item.minor_category : view === 'vendor' ? selectedMinor : null,
          p_vendor: view === 'vendor' ? item.vendor : null,
          p_start_date: startDate,
          p_end_date: endDate,
          p_sort_field: transactionSort.field,
          p_sort_direction: transactionSort.direction
        }
      );

      if (error) throw error;
      setTransactions(transactionData);
      setSelectedItem(item);
      setShowTransactions(true);
    } catch (error) {
      console.error('Error fetching transactions:', error);
    } finally {
      setTransactionsLoading(false);
    }
  };

  const handleSortChange = (field) => {
    setTransactionSort(prev => ({
      field,
      direction: prev.field === field && prev.direction === 'desc' ? 'asc' : 'desc'
    }));
  };

  const chartData = {
    labels: data.map(item => 
      view === 'major' ? item.major_category :
      view === 'minor' ? item.minor_category :
      item.vendor
    ),
    datasets: [{
      data: data.map(item => item.total_spend),
      backgroundColor: generateColors(data.length),
      borderWidth: 0
    }]
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: '75%',
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const value = context.raw;
            const total = context.dataset.data.reduce((a, b) => a + b, 0);
            const percentage = ((value / total) * 100).toFixed(1);
            return `$${value.toLocaleString()} (${percentage}%)`;
          }
        }
      }
    }
  };

  const filteredTransactions = transactions.filter(transaction => 
    transaction.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
    (transaction.major_category || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
    (transaction.minor_category || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
    (transaction.vendor || '').toLowerCase().includes(searchTerm.toLowerCase())
  );

  const downloadCSV = () => {
    // Define CSV headers
    const headers = [
      'Date',
      'Description',
      'Amount',
      'Major Category',
      'Minor Category',
      'Vendor'
    ];

    // Convert transactions to CSV rows
    const csvRows = [
      headers.join(','), // Header row
      ...filteredTransactions.map(t => [
        new Date(t.date).toLocaleDateString(),
        `"${t.description.replace(/"/g, '""')}"`, // Escape quotes in description
        t.amount.toFixed(2),
        `"${(t.major_category || '').replace(/"/g, '""')}"`,
        `"${(t.minor_category || '').replace(/"/g, '""')}"`,
        `"${(t.vendor || '').replace(/"/g, '""')}"`
      ].join(','))
    ].join('\n');

    // Create and trigger download
    const blob = new Blob([csvRows], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', `transactions_${new Date().toISOString().split('T')[0]}.csv`);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const vendorsChartData = {
    labels: topVendors.map(v => v.vendor),
    datasets: [{
      data: topVendors.map(v => v.total_spend),
      backgroundColor: generateColors(topVendors.length),
      borderWidth: 0,
      borderRadius: 4
    }]
  };

  const vendorsChartOptions = {
    indexAxis: 'y',
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const value = context.raw;
            return `$${value.toLocaleString()}`;
          }
        }
      }
    },
    scales: {
      x: {
        grid: {
          display: false,
          drawBorder: false
        },
        ticks: {
          callback: (value) => `$${value.toLocaleString()}`
        }
      },
      y: {
        grid: {
          display: false,
          drawBorder: false
        }
      }
    }
  };

  return (
    <div className="bg-navy-800 rounded-xl p-4 md:p-6">
        
        {/* Year Filter Chips */}
        <div className="flex flex-wrap gap-2 mb-6">
          <button
            onClick={() => {
              setSelectedYear('all');
              setDateRange('all');
            }}
            className={`px-4 py-2 rounded-full text-sm font-medium transition-colors ${
              selectedYear === 'all'
                ? currentTheme?.buttonBg || 'bg-purple-500'
                : 'bg-navy-700 hover:bg-navy-600'
            }`}
          >
            All Time
          </button>
          {['2021', '2022', '2023', '2024', '2025'].map(year => (
            <button
              key={year}
              onClick={() => {
                setSelectedYear(year);
                if (dateRange === 'custom') setDateRange('all');
              }}
              className={`px-4 py-2 rounded-full text-sm font-medium transition-colors ${
                selectedYear === year
                  ? currentTheme?.buttonBg || 'bg-purple-500'
                  : 'bg-navy-700 hover:bg-navy-600'
              }`}
            >
              {year}
            </button>
          ))}
        </div>

      {/* Header */}
      <div className="flex flex-col space-y-4 mb-6">
        <div className="flex items-center justify-between gap-4">
          <h3 className="text-xl font-semibold text-gray-200">Category Analysis</h3>
          <div className="flex items-center gap-4">
            <select
              value={dateRange}
              onChange={(e) => setDateRange(e.target.value)}
              disabled={selectedYear === 'all'}
              className="bg-navy-700 border border-navy-600 rounded-lg px-3 py-2 text-sm text-white focus:outline-none focus:ring-2 focus:ring-purple-500 disabled:opacity-50"
            >
              <option value="all">All Months</option>
              <option value="1">January</option>
              <option value="2">February</option>
              <option value="3">March</option>
              <option value="4">April</option>
              <option value="5">May</option>
              <option value="6">June</option>
              <option value="7">July</option>
              <option value="8">August</option>
              <option value="9">September</option>
              <option value="10">October</option>
              <option value="11">November</option>
              <option value="12">December</option>
              <option value="custom">Custom Range</option>
            </select>
          </div>
        </div>

        {dateRange === 'custom' && (
          <div className="flex flex-col sm:flex-row gap-4">
            <input
              type="date"
              value={customStartDate}
              onChange={(e) => setCustomStartDate(e.target.value)}
              className="bg-navy-700 border border-navy-600 rounded-lg px-3 py-2 text-sm text-white focus:outline-none focus:ring-2 focus:ring-purple-500"
            />
            <input
              type="date"
              value={customEndDate}
              onChange={(e) => setCustomEndDate(e.target.value)}
              className="bg-navy-700 border border-navy-600 rounded-lg px-3 py-2 text-sm text-white focus:outline-none focus:ring-2 focus:ring-purple-500"
            />
          </div>
        )}

        {/* Breadcrumbs */}
        {breadcrumbs.length > 0 && (
          <div className="flex items-center space-x-2 text-sm text-gray-400">
            <button
              onClick={() => handleBreadcrumbClick(0)}
              className="hover:text-white transition-colors"
            >
              Categories
            </button>
            {breadcrumbs.map((crumb, index) => (
              <React.Fragment key={index}>
                <ChevronRightIcon className="w-4 h-4" />
                <button
                  onClick={() => handleBreadcrumbClick(index + 1)}
                  className="hover:text-white transition-colors"
                >
                  {crumb.label}
                </button>
              </React.Fragment>
            ))}
          </div>
        )}
      </div>

      {loading ? (
        <div className="flex items-center justify-center h-64">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-purple-500"></div>
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* Chart */}
          <div className="relative" style={{ height: '300px' }}>
            <Doughnut data={chartData} options={chartOptions} />
            {data.length > 0 && (
              <div className="absolute inset-0 flex items-center justify-center">
                <div className="text-center">
                  <div className="text-2xl font-bold">
                    ${data.reduce((sum, item) => sum + item.total_spend, 0).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                  </div>
                  <div className="text-sm text-gray-400">Total Spend</div>
                </div>
              </div>
            )}
          </div>

          {/* Category List */}
          <div className="space-y-3 overflow-y-auto max-h-[300px]">
            {data.map((item, index) => {
              const label = view === 'major' ? item.major_category :
                          view === 'minor' ? item.minor_category :
                          item.vendor;
              const spend = item.total_spend;
              const total = data.reduce((sum, item) => sum + item.total_spend, 0);
              const percentage = ((spend / total) * 100).toFixed(1);

              return (
                <div
                  key={index}
                  className={`bg-navy-900/50 border border-navy-700/50 rounded-lg p-4 ${
                    view !== 'vendor' ? 'cursor-pointer hover:bg-navy-800/50' : ''
                  } transition-colors`}
                >
                  <div className="flex items-center justify-between">
                    <div 
                      className="flex items-center gap-3 flex-1"
                      onClick={() => view !== 'vendor' && handleDrillDown(item)}
                    >
                      <div
                        className="w-4 h-4 rounded"
                        style={{ backgroundColor: chartData.datasets[0].backgroundColor[index] }}
                      />
                      <div>
                        <span className="font-medium">{label}</span>
                        <div className="text-sm text-gray-400">
                          {item.transaction_count} transactions
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center gap-4">
                      <div className="text-right">
                        <div className="font-semibold">${spend.toLocaleString()}</div>
                        <div className="text-sm text-gray-400">{percentage}% of total</div>
                      </div>
                      <button
                        onClick={() => fetchTransactions(item)}
                        className="p-2 hover:bg-navy-700 rounded-lg transition-colors"
                        title="View transactions"
                      >
                        <ListBulletIcon className="w-5 h-5 text-gray-400" />
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}

      {/* Top 10 Vendors Section */}
      <div className="mt-8">
        <h3 className="text-xl font-semibold text-gray-200 mb-4">Top 10 Vendors by Spend</h3>
        <div className="bg-navy-900/50 rounded-xl p-6" style={{ height: '400px' }}>
          {topVendors.length > 0 ? (
            <Bar data={vendorsChartData} options={vendorsChartOptions} />
          ) : (
            <div className="flex items-center justify-center h-full text-gray-400">
              No vendor data available for the selected filters
            </div>
          )}
        </div>
      </div>

      {/* Transactions Modal */}
      {showTransactions && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4">
          <div className="bg-navy-800 rounded-xl w-full max-w-4xl max-h-[90vh] flex flex-col">
            <div className="p-6 border-b border-navy-700">
              <div className="flex flex-col gap-4">
                <div className="flex justify-between items-center">
                  <div>
                    <h3 className="text-xl font-semibold text-white">
                      {view === 'major' ? selectedItem.major_category :
                       view === 'minor' ? selectedItem.minor_category :
                       selectedItem.vendor}
                    </h3>
                    <p className="text-sm text-gray-400">
                      {dateRange === 'all' ? 'All Time' :
                       dateRange === 'custom' ? `${customStartDate} to ${customEndDate}` :
                       `Last ${dateRange === '30d' ? '30 Days' :
                                dateRange === '90d' ? '90 Days' :
                                dateRange === '180d' ? '180 Days' : 'Year'}`}
                    </p>
                  </div>
                  <div className="flex items-center gap-4">
                    <button
                      onClick={downloadCSV}
                      className="px-3 py-2 bg-navy-700 hover:bg-navy-600 text-white rounded-lg text-sm transition-colors focus:outline-none focus:ring-2 focus:ring-purple-500"
                    >
                      Download CSV
                    </button>
                    <select
                      value={`${transactionSort.field}-${transactionSort.direction}`}
                      onChange={(e) => {
                        const [field, direction] = e.target.value.split('-');
                        setTransactionSort({ field, direction });
                      }}
                      className="bg-navy-700 border border-navy-600 rounded-lg px-3 py-2 text-sm text-white focus:outline-none focus:ring-2 focus:ring-purple-500"
                    >
                      <option value="amount-desc">Highest Amount</option>
                      <option value="amount-asc">Lowest Amount</option>
                      <option value="date-desc">Most Recent</option>
                      <option value="date-asc">Oldest</option>
                      <option value="description-asc">A-Z</option>
                      <option value="description-desc">Z-A</option>
                    </select>
                    <button 
                      onClick={() => setShowTransactions(false)}
                      className="text-gray-400 hover:text-white transition-colors"
                    >
                      <XMarkIcon className="w-6 h-6" />
                    </button>
                  </div>
                </div>

                {/* Search Box */}
                <div className="relative">
                  <input
                    type="text"
                    placeholder="Search transactions..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="w-full bg-navy-700 border border-navy-600 rounded-lg px-4 py-2 pl-10 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-purple-500"
                  />
                  <div className="absolute inset-y-0 left-3 flex items-center pointer-events-none">
                    <svg className="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex-1 overflow-auto p-6">
              {transactionsLoading ? (
                <div className="flex items-center justify-center h-32">
                  <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-purple-500"></div>
                </div>
              ) : filteredTransactions.length === 0 ? (
                <div className="text-center text-gray-400 py-8">
                  {searchTerm ? 'No transactions match your search' : 'No transactions found for this period'}
                </div>
              ) : (
                <div className="space-y-4">
                  {filteredTransactions.map((transaction, index) => (
                    <div 
                      key={index}
                      className="bg-navy-900/50 border border-navy-700/50 rounded-lg p-4"
                    >
                      <div className="flex justify-between items-start">
                        <div className="space-y-1">
                          <div className="font-medium text-white">{transaction.description}</div>
                          <div className="text-sm text-gray-400">
                            {new Date(transaction.date).toLocaleDateString()}
                          </div>
                          <div className="flex flex-wrap gap-2 mt-2">
                            {transaction.major_category && (
                              <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-purple-900/50 text-purple-200">
                                {transaction.major_category}
                              </span>
                            )}
                            {transaction.minor_category && (
                              <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-900/50 text-blue-200">
                                {transaction.minor_category}
                              </span>
                            )}
                            {transaction.vendor && (
                              <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-teal-900/50 text-teal-200">
                                {transaction.vendor}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="text-right font-semibold">
                          ${transaction.amount.toLocaleString()}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default CategoryAnalysis; 