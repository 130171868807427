import React, { useState, useEffect } from 'react';
import Card from '../components/Card';
import { useAuth } from '../hooks/useAuth';
import { supabase } from '../supabase/config';
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/24/solid';
import { XMarkIcon, PaperClipIcon, DocumentIcon } from '@heroicons/react/24/outline';

function Tech({ currentTheme }) {
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [techPurchases, setTechPurchases] = useState([]);
  const [filteredPurchases, setFilteredPurchases] = useState([]);
  const [timeFilter, setTimeFilter] = useState('all');
  const [sourceFilter, setSourceFilter] = useState('all');
  const [claimedFilter, setClaimedFilter] = useState('all');
  const [sources, setSources] = useState([]);
  const [dateRange, setDateRange] = useState({
    start: '2018-01-01',
    end: new Date().toISOString().split('T')[0]
  });
  const [sortConfig, setSortConfig] = useState({
    key: 'purchase_date',
    direction: 'desc'
  });
  const [summaryData, setSummaryData] = useState({
    claimedThisYear: 0,
    readyToClaim: 5000,
    lifetimeClaims: 0
  });
  const [selectedPurchase, setSelectedPurchase] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [uploadingReceipt, setUploadingReceipt] = useState(false);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [showAddModal, setShowAddModal] = useState(false);
  const [newPurchase, setNewPurchase] = useState({
    source: '',
    purchase_date: new Date().toISOString().split('T')[0],
    supplier: '',
    item: '',
    cost: '',
    claimed: false,
    claim_date: '',
    claim_discount: '',
    total_cost_to_us: ''
  });
  const [editedPurchase, setEditedPurchase] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      if (!user) return;
      setLoading(true);

      try {
        // Get current year's start date
        const currentYear = new Date().getFullYear();
        const yearStart = new Date(currentYear - 1, new Date().getMonth(), new Date().getDate()).toISOString();

        // Fetch tech purchases
        const { data: purchases, error: purchasesError } = await supabase
          .from('tech_purchases')
          .select('*')
          .order('purchase_date', { ascending: false });

        if (purchasesError) throw purchasesError;

        // Extract unique sources
        const uniqueSources = [...new Set(purchases?.map(p => p.source) || [])];
        setSources(uniqueSources);

        setTechPurchases(purchases || []);
        applyFilters(purchases || [], timeFilter, dateRange, sourceFilter, claimedFilter);

        // Calculate summary data
        const thisYearClaims = purchases
          ?.filter(item => {
            const claimDate = new Date(item.claim_date);
            return claimDate >= new Date(yearStart);
          })
          .reduce((sum, item) => sum + (item.claim_discount || 0), 0);

        const lifetimeClaims = purchases
          ?.reduce((sum, item) => sum + (item.claim_discount || 0), 0);

        setSummaryData({
          claimedThisYear: thisYearClaims || 0,
          readyToClaim: Math.max(5000 - (thisYearClaims || 0), 0),
          lifetimeClaims: lifetimeClaims || 0
        });

      } catch (error) {
        console.error('Error fetching tech data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [user]);

  const applyFilters = (purchases, timeFilterValue, dateRangeValue, sourceFilterValue, claimedFilterValue) => {
    let filtered = [...purchases];

    // Apply search filter
    if (searchQuery.trim()) {
      const query = searchQuery.toLowerCase();
      filtered = filtered.filter(purchase => 
        purchase.item.toLowerCase().includes(query)
      );
    }

    // Apply time filter
    if (timeFilterValue === 'year') {
      const yearAgo = new Date();
      yearAgo.setFullYear(yearAgo.getFullYear() - 1);
      filtered = filtered.filter(purchase => new Date(purchase.purchase_date) >= yearAgo);
    }

    // Apply date range filter
    filtered = filtered.filter(purchase => {
      const purchaseDate = new Date(purchase.purchase_date);
      return purchaseDate >= new Date(dateRangeValue.start) && 
             purchaseDate <= new Date(dateRangeValue.end);
    });

    // Apply source filter
    if (sourceFilterValue !== 'all') {
      filtered = filtered.filter(purchase => purchase.source === sourceFilterValue);
    }

    // Apply claimed filter
    if (claimedFilterValue !== 'all') {
      filtered = filtered.filter(purchase => purchase.claimed === (claimedFilterValue === 'true'));
    }

    // Apply sorting
    filtered.sort((a, b) => {
      let aValue = a[sortConfig.key];
      let bValue = b[sortConfig.key];

      // Handle special cases for sorting
      if (sortConfig.key === 'purchase_date' || sortConfig.key === 'claim_date') {
        aValue = aValue ? new Date(aValue).getTime() : 0;
        bValue = bValue ? new Date(bValue).getTime() : 0;
      } else if (typeof aValue === 'string') {
        aValue = aValue.toLowerCase();
        bValue = bValue.toLowerCase();
      } else if (typeof aValue === 'boolean') {
        aValue = aValue ? 1 : 0;
        bValue = bValue ? 1 : 0;
      }

      if (aValue < bValue) {
        return sortConfig.direction === 'asc' ? -1 : 1;
      }
      if (aValue > bValue) {
        return sortConfig.direction === 'asc' ? 1 : -1;
      }
      return 0;
    });

    setFilteredPurchases(filtered);
  };

  useEffect(() => {
    applyFilters(techPurchases, timeFilter, dateRange, sourceFilter, claimedFilter);
  }, [timeFilter, dateRange, sourceFilter, claimedFilter, techPurchases, sortConfig, searchQuery]);

  const handleSort = (key) => {
    setSortConfig(prevConfig => ({
      key,
      direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc'
    }));
  };

  const renderSortIcon = (columnKey) => {
    if (sortConfig.key !== columnKey) {
      return <div className="w-4 h-4" />; // Empty space to maintain alignment
    }
    return sortConfig.direction === 'asc' ? (
      <ChevronUpIcon className="w-4 h-4" />
    ) : (
      <ChevronDownIcon className="w-4 h-4" />
    );
  };

  const formatCurrency = (value) => {
    return `$${Math.round(value).toLocaleString()}`;
  };

  const formatDate = (dateString) => {
    if (!dateString) return '-';
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  const handlePurchaseClick = (purchase) => {
    setSelectedPurchase(purchase);
    setEditedPurchase(purchase);
    setShowModal(true);
    if (purchase.receipt_url) {
      getReceiptPreview(purchase.receipt_url);
    } else {
      setPreviewUrl(null);
    }
  };

  const handleUploadReceipt = async (event, purchaseId) => {
    try {
      setUploadingReceipt(true);
      const file = event.target.files[0];
      if (!file) return;

      const fileExt = file.name.split('.').pop();
      const fileName = `${purchaseId}-${Date.now()}.${fileExt}`;
      const filePath =`receipts/${fileName}`;

      // Upload file to Supabase Storage
      const { error: uploadError } = await supabase.storage
         .from('tech-receipts')
        .upload(filePath, file);

      if (uploadError) throw uploadError;

      // Update the tech_purchases table with the receipt URL
      const { error: updateError } = await supabase
        .from('tech_purchases')
        .update({ receipt_url: filePath })
        .eq('id', purchaseId);

      if (updateError) throw updateError;

      // Refresh the purchases data
      const { data: purchases, error: purchasesError } = await supabase
        .from('tech_purchases')
        .select('*')
        .order('purchase_date', { ascending: false });

      if (purchasesError) throw purchasesError;
      setTechPurchases(purchases || []);
      
      // Update the selected purchase
      const updatedPurchase = purchases.find(p => p.id === purchaseId);
      setSelectedPurchase(updatedPurchase);

    } catch (error) {
      console.error('Error uploading receipt:', error);
      alert('Failed to upload receipt');
    } finally {
      setUploadingReceipt(false);
    }
  };

  const downloadReceipt = async (receiptUrl) => {
    try {
      const { data, error } = await supabase.storage
        .from('tech-receipts')
        .download(receiptUrl);

      if (error) throw error;

      // Create a download link
      const url = URL.createObjectURL(data);
      const a = document.createElement('a');
      a.href = url;
      a.download = receiptUrl.split('/').pop();
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading receipt:', error);
      alert('Failed to download receipt');
    }
  };

  const getReceiptPreview = async (receiptUrl) => {
    try {
      const { data, error } = await supabase.storage
        .from('tech-receipts')
        .createSignedUrl(receiptUrl, 3600); // 1 hour expiry

      if (error) throw error;
      setPreviewUrl(data.signedUrl);
    } catch (error) {
      console.error('Error getting receipt preview:', error);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setPreviewUrl(null);
  };

  const handleAddPurchase = async () => {
    try {
      newPurchase.claim_date === "" ? newPurchase.claim_date = null : newPurchase.claim_date = newPurchase.claim_date;
      const { error } = await supabase
        .from('tech_purchases')
        .insert([{
          ...newPurchase,
          cost: parseFloat(newPurchase.cost) || 0,
          claim_discount: parseFloat(newPurchase.claim_discount) || 0,
          total_cost_to_us: parseFloat(newPurchase.total_cost_to_us) || 0,
          user_id: user.id
        }]);

      if (error) throw error;

      // Refresh the purchases data
      const { data: purchases, error: purchasesError } = await supabase
        .from('tech_purchases')
        .select('*')
        .order('purchase_date', { ascending: false });

      if (purchasesError) throw purchasesError;
      setTechPurchases(purchases || []);
      setShowAddModal(false);
      
      // Reset form
      setNewPurchase({
        source: '',
        purchase_date: new Date().toISOString().split('T')[0],
        supplier: '',
        item: '',
        cost: '',
        claimed: false,
        claim_date: '',
        claim_discount: '',
        total_cost_to_us: ''
      });

    } catch (error) {
      console.error('Error adding purchase:', error);
      alert('Failed to add purchase');
    }
  };

  const handleTimeFilterChange = (newTimeFilter) => {
    setTimeFilter(newTimeFilter);
    // Update sort direction based on time filter
    setSortConfig(prev => ({
      key: 'purchase_date',
      direction: newTimeFilter === 'year' ? 'asc' : 'desc'
    }));
  };

  const handleUpdatePurchase = async () => {
    try {
      // Calculate the new total cost
      const totalCost = 
        parseFloat(editedPurchase.cost || 0) -
        parseFloat(editedPurchase.claim_discount || 0) -
        parseFloat(editedPurchase.after_discount || 0) -
        parseFloat(editedPurchase.sale_price || 0);

      const { error } = await supabase
        .from('tech_purchases')
        .update({
          ...editedPurchase,
          total_cost_to_us: totalCost
        })
        .eq('id', editedPurchase.id);

      if (error) throw error;

      // Refresh the purchases data
      const { data: purchases, error: purchasesError } = await supabase
        .from('tech_purchases')
        .select('*')
        .order('purchase_date', { ascending: false });

      if (purchasesError) throw purchasesError;
      
      setTechPurchases(purchases || []);
      setSelectedPurchase({ ...editedPurchase, total_cost_to_us: totalCost });
      
    } catch (error) {
      console.error('Error updating purchase:', error);
      alert('Failed to update purchase');
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className={`animate-spin rounded-full h-8 w-8 border-b-2 ${currentTheme?.buttonBg || 'border-cyan-500'}`}></div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="space-y-6">
        {/* Summary Cards */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {/* Total Claimed This Year */}
          <Card currentTheme={currentTheme}>
            <h3 className="text-lg font-medium text-gray-200 mb-2">Total Claimed This Year</h3>
            <div className="text-3xl font-bold text-white">
              {formatCurrency(summaryData.claimedThisYear)}
            </div>
          </Card>

          {/* Ready to Claim */}
          <Card currentTheme={currentTheme}>
            <h3 className="text-lg font-medium text-gray-200 mb-2">Ready to Claim</h3>
            <div className="text-3xl font-bold text-white">
              {formatCurrency(summaryData.readyToClaim)}
            </div>
          </Card>

          {/* Lifetime Claims */}
          <Card currentTheme={currentTheme}>
            <h3 className="text-lg font-medium text-gray-200 mb-2">Lifetime Claims</h3>
            <div className="text-3xl font-bold text-white">
              {formatCurrency(summaryData.lifetimeClaims)}
            </div>
          </Card>
        </div>

        {/* Filters */}
        <Card currentTheme={currentTheme}>
          <div className="flex flex-col gap-6">
            <div className="flex justify-between items-center">
              <h3 className="text-lg font-medium">Filters</h3>
              <button
                onClick={() => setShowAddModal(true)}
                className={`px-4 py-2 rounded-lg text-sm font-medium ${currentTheme?.buttonBg || 'bg-purple-600'} hover:opacity-90 transition-opacity`}
              >
                Add Purchase
              </button>
            </div>
            <div className="flex flex-col md:flex-row md:items-center gap-6">
              <div className="flex-1">
                <h3 className="text-sm font-medium text-gray-400 mb-2">Time Period</h3>
                <div className="flex space-x-4">
                  <button
                    onClick={() => handleTimeFilterChange('all')}
                    className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors
                      ${timeFilter === 'all' 
                        ? currentTheme?.buttonBg || 'bg-purple-600'
                        : 'bg-navy-700/50 hover:bg-navy-700'}`}
                  >
                    All Time
                  </button>
                  <button
                    onClick={() => handleTimeFilterChange('year')}
                    className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors
                      ${timeFilter === 'year'
                        ? currentTheme?.buttonBg || 'bg-purple-600'
                        : 'bg-navy-700/50 hover:bg-navy-700'}`}
                  >
                    Last 12 Months
                  </button>
                </div>
              </div>

              <div className="flex-1">
                <h3 className="text-sm font-medium text-gray-400 mb-2">Date Range</h3>
                <div className="flex items-center space-x-4">
                  <input
                    type="date"
                    value={dateRange.start}
                    onChange={(e) => setDateRange(prev => ({ ...prev, start: e.target.value }))}
                    className="bg-navy-700/50 border border-navy-600/50 rounded-lg px-3 py-2 text-sm"
                  />
                  <span className="text-gray-400">to</span>
                  <input
                    type="date"
                    value={dateRange.end}
                    onChange={(e) => setDateRange(prev => ({ ...prev, end: e.target.value }))}
                    className="bg-navy-700/50 border border-navy-600/50 rounded-lg px-3 py-2 text-sm"
                  />
                </div>
              </div>
            </div>

            <div className="flex flex-col md:flex-row md:items-center gap-6">
              <div className="flex-1">
                <h3 className="text-sm font-medium text-gray-400 mb-2">Source</h3>
                <select
                  value={sourceFilter}
                  onChange={(e) => setSourceFilter(e.target.value)}
                  className="w-full bg-navy-700/50 border border-navy-600/50 rounded-lg px-3 py-2 text-sm"
                >
                  <option value="all">All Sources</option>
                  {sources.map(source => (
                    <option key={source} value={source}>{source}</option>
                  ))}
                </select>
              </div>

              <div className="flex-1">
                <h3 className="text-sm font-medium text-gray-400 mb-2">Claimed Status</h3>
                <select
                  value={claimedFilter}
                  onChange={(e) => setClaimedFilter(e.target.value)}
                  className="w-full bg-navy-700/50 border border-navy-600/50 rounded-lg px-3 py-2 text-sm"
                >
                  <option value="all">All Status</option>
                  <option value="true">Claimed</option>
                  <option value="false">Not Claimed</option>
                </select>
              </div>
            </div>
          </div>
        </Card>

        {/* Tech Purchases Table */}
        <Card currentTheme={currentTheme}>
          <div className="flex flex-col gap-4">
            <div className="flex justify-between items-center">
              <h2 className="text-2xl font-bold">Tech Purchases</h2>
              <div className="flex items-center space-x-2">
                <input
                  type="text"
                  placeholder="Search items..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="bg-navy-700/50 border border-navy-600/50 rounded-lg px-3 py-2 text-sm w-64"
                />
                <span className="text-sm text-gray-400">
                  Showing {filteredPurchases.length} items
                </span>
              </div>
            </div>
            <div className="overflow-x-auto">
              <table className="w-full">
                <thead>
                  <tr className="border-b border-gray-700">
                    <th 
                      className="py-3 px-4 text-left text-sm font-semibold text-gray-400 cursor-pointer hover:text-white"
                      onClick={() => handleSort('item')}
                    >
                      <div className="flex items-center space-x-1">
                        <span>Item</span>
                        {renderSortIcon('item')}
                      </div>
                    </th>
                    <th 
                      className="py-3 px-4 text-left text-sm font-semibold text-gray-400 cursor-pointer hover:text-white"
                      onClick={() => handleSort('source')}
                    >
                      <div className="flex items-center space-x-1">
                        <span>Source</span>
                        {renderSortIcon('source')}
                      </div>
                    </th>
                    <th 
                      className="py-3 px-4 text-left text-sm font-semibold text-gray-400 cursor-pointer hover:text-white"
                      onClick={() => handleSort('purchase_date')}
                    >
                      <div className="flex items-center space-x-1">
                        <span>Purchase Date</span>
                        {renderSortIcon('purchase_date')}
                      </div>
                    </th>
                    <th 
                      className="py-3 px-4 text-right text-sm font-semibold text-gray-400 cursor-pointer hover:text-white"
                      onClick={() => handleSort('cost')}
                    >
                      <div className="flex items-center justify-end space-x-1">
                        <span>Cost</span>
                        {renderSortIcon('cost')}
                      </div>
                    </th>
                    <th 
                      className="py-3 px-4 text-center text-sm font-semibold text-gray-400 cursor-pointer hover:text-white"
                      onClick={() => handleSort('claimed')}
                    >
                      <div className="flex items-center justify-center space-x-1">
                        <span>Claimed</span>
                        {renderSortIcon('claimed')}
                      </div>
                    </th>
                    <th 
                      className="py-3 px-4 text-left text-sm font-semibold text-gray-400 cursor-pointer hover:text-white"
                      onClick={() => handleSort('claim_date')}
                    >
                      <div className="flex items-center space-x-1">
                        <span>Claim Date</span>
                        {renderSortIcon('claim_date')}
                      </div>
                    </th>
                    <th 
                      className="py-3 px-4 text-right text-sm font-semibold text-gray-400 cursor-pointer hover:text-white"
                      onClick={() => handleSort('claim_discount')}
                    >
                      <div className="flex items-center justify-end space-x-1">
                        <span>Claim Discount</span>
                        {renderSortIcon('claim_discount')}
                      </div>
                    </th>
                    <th 
                      className="py-3 px-4 text-right text-sm font-semibold text-gray-400 cursor-pointer hover:text-white"
                      onClick={() => handleSort('total_cost_to_us')}
                    >
                      <div className="flex items-center justify-end space-x-1">
                        <span>Total Cost</span>
                        {renderSortIcon('total_cost_to_us')}
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredPurchases.map((purchase, index) => (
                    <tr 
                      key={purchase.id}
                      className={`
                        border-b border-gray-700/50 hover:bg-gray-700/20 transition-colors cursor-pointer
                        ${index % 2 === 0 ? 'bg-gray-700/10' : ''}
                      `}
                      onClick={() => handlePurchaseClick(purchase)}
                    >
                      <td className="py-3 px-4 text-left whitespace-nowrap">
                        <div className="max-w-[200px] overflow-hidden" title={purchase.item}>
                          <span className="truncate inline-block w-full">{purchase.item}</span>
                        </div>
                      </td>
                      <td className="py-3 px-4 text-left whitespace-nowrap">{purchase.source}</td>
                      <td className="py-3 px-4 text-left whitespace-nowrap">{formatDate(purchase.purchase_date)}</td>
                      <td className="py-3 px-4 text-right whitespace-nowrap">{formatCurrency(purchase.cost)}</td>
                      <td className="py-3 px-4 text-center whitespace-nowrap">
                        <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium
                          ${purchase.claimed ? 'bg-green-900/50 text-green-400' : 'bg-gray-700/50 text-gray-400'}`}>
                          {purchase.claimed ? 'true': 'false'}
                        </span>
                      </td>
                      <td className="py-3 px-4 text-left whitespace-nowrap">{formatDate(purchase.claim_date)}</td>
                      <td className="py-3 px-4 text-right whitespace-nowrap">{formatCurrency(purchase.claim_discount)}</td>
                      <td className="py-3 px-4 text-right whitespace-nowrap">{formatCurrency(purchase.total_cost_to_us)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </Card>

        {/* Add Purchase Modal */}
        {showAddModal && (
          <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4">
            <div className={`bg-navy-800 rounded-lg shadow-xl max-w-2xl w-full max-h-[90vh] overflow-y-auto ${currentTheme?.cardBg || 'bg-navy-800'}`}>
              <div className="p-6">
                <div className="flex justify-between items-start mb-6">
                  <h2 className="text-2xl font-bold">Add New Purchase</h2>
                  <button
                    onClick={() => setShowAddModal(false)}
                    className="text-gray-400 hover:text-white"
                  >
                    <XMarkIcon className="w-6 h-6" />
                  </button>
                </div>

                <div className="space-y-4">
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-400 mb-1">Source</label>
                      <input
                        type="text"
                        value={newPurchase.source}
                        onChange={(e) => setNewPurchase(prev => ({ ...prev, source: e.target.value }))}
                        className="w-full bg-navy-700/50 border border-navy-600/50 rounded-lg px-3 py-2 text-sm"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-400 mb-1">Purchase Date</label>
                      <input
                        type="date"
                        value={newPurchase.purchase_date}
                        onChange={(e) => setNewPurchase(prev => ({ ...prev, purchase_date: e.target.value }))}
                        className="w-full bg-navy-700/50 border border-navy-600/50 rounded-lg px-3 py-2 text-sm"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-400 mb-1">Supplier</label>
                      <input
                        type="text"
                        value={newPurchase.supplier}
                        onChange={(e) => setNewPurchase(prev => ({ ...prev, supplier: e.target.value }))}
                        className="w-full bg-navy-700/50 border border-navy-600/50 rounded-lg px-3 py-2 text-sm"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-400 mb-1">Item</label>
                      <input
                        type="text"
                        value={newPurchase.item}
                        onChange={(e) => setNewPurchase(prev => ({ ...prev, item: e.target.value }))}
                        className="w-full bg-navy-700/50 border border-navy-600/50 rounded-lg px-3 py-2 text-sm"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-400 mb-1">Cost</label>
                      <input
                        type="number"
                        value={newPurchase.cost}
                        onChange={(e) => setNewPurchase(prev => ({ ...prev, cost: e.target.value }))}
                        className="w-full bg-navy-700/50 border border-navy-600/50 rounded-lg px-3 py-2 text-sm"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-400 mb-1">Claimed</label>
                      <select
                        value={newPurchase.claimed}
                        onChange={(e) => setNewPurchase(prev => ({ 
                          ...prev, 
                          claimed: e.target.value === 'true',
                          claim_date: e.target.value === 'true' ? new Date().toISOString().split('T')[0] : ''
                        }))}
                        className="w-full bg-navy-700/50 border border-navy-600/50 rounded-lg px-3 py-2 text-sm"
                      >
                        <option value="false">No</option>
                        <option value="true">Yes</option>
                      </select>
                    </div>
                    {newPurchase.claimed && (
                      <>
                        <div>
                          <label className="block text-sm font-medium text-gray-400 mb-1">Claim Date</label>
                          <input
                            type="date"
                            value={newPurchase.claim_date}
                            onChange={(e) => setNewPurchase(prev => ({ ...prev, claim_date: e.target.value }))}
                            className="w-full bg-navy-700/50 border border-navy-600/50 rounded-lg px-3 py-2 text-sm"
                          />
                        </div>
                        <div>
                          <label className="block text-sm font-medium text-gray-400 mb-1">Claim Discount</label>
                          <input
                            type="number"
                            value={newPurchase.claim_discount}
                            onChange={(e) => setNewPurchase(prev => ({ ...prev, claim_discount: e.target.value }))}
                            className="w-full bg-navy-700/50 border border-navy-600/50 rounded-lg px-3 py-2 text-sm"
                          />
                        </div>
                      </>
                    )}
                    <div>
                      <label className="block text-sm font-medium text-gray-400 mb-1">Total Cost to Us</label>
                      <input
                        type="number"
                        value={newPurchase.total_cost_to_us}
                        onChange={(e) => setNewPurchase(prev => ({ ...prev, total_cost_to_us: e.target.value }))}
                        className="w-full bg-navy-700/50 border border-navy-600/50 rounded-lg px-3 py-2 text-sm"
                      />
                    </div>
                  </div>

                  <div className="flex justify-end space-x-4 mt-6">
                    <button
                      onClick={() => setShowAddModal(false)}
                      className="px-4 py-2 rounded-lg text-sm font-medium bg-gray-700/50 hover:bg-gray-700 transition-colors"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={handleAddPurchase}
                      className={`px-4 py-2 rounded-lg text-sm font-medium ${currentTheme?.buttonBg || 'bg-purple-600'} hover:opacity-90 transition-opacity`}
                    >
                      Add Purchase
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Purchase Details Modal */}
        {showModal && selectedPurchase && (
          <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4">
            <div className={`bg-navy-800 rounded-lg shadow-xl max-w-2xl w-full max-h-[90vh] overflow-y-auto ${currentTheme?.cardBg || 'bg-navy-800'}`}>
              <div className="p-6">
                <div className="flex justify-between items-start mb-6">
                  <h2 className="text-2xl font-bold">{selectedPurchase.item}</h2>
                  <button
                    onClick={handleCloseModal}
                    className="text-gray-400 hover:text-white"
                  >
                    <XMarkIcon className="w-6 h-6" />
                  </button>
                </div>

                <div className="space-y-6">
                  {/* Purchase Details */}
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <h3 className="text-sm font-medium text-gray-400">Source</h3>
                      <p className="mt-1">{selectedPurchase.source}</p>
                    </div>
                    <div>
                      <h3 className="text-sm font-medium text-gray-400">Purchase Date</h3>
                      <p className="mt-1">{formatDate(selectedPurchase.purchase_date)}</p>
                    </div>
                    <div>
                      <h3 className="text-sm font-medium text-gray-400">Cost</h3>
                      <p className="mt-1">{formatCurrency(selectedPurchase.cost)}</p>
                    </div>
                    
                    {/* Editable Fields */}
                    <div>
                      <h3 className="text-sm font-medium text-gray-400">Claimed</h3>
                      <select
                        value={editedPurchase.claimed}
                        onChange={(e) => setEditedPurchase(prev => ({
                          ...prev,
                          claimed: e.target.value === 'true',
                          claim_date: e.target.value === 'true' ? new Date().toISOString().split('T')[0] : ''
                        }))}
                        className="mt-1 w-full bg-navy-700/50 border border-navy-600/50 rounded-lg px-3 py-2 text-sm"
                      >
                        <option value="false">No</option>
                        <option value="true">Yes</option>
                      </select>
                    </div>
                    
                    {editedPurchase.claimed && (
                      <>
                        <div>
                          <h3 className="text-sm font-medium text-gray-400">Claim Date</h3>
                          <input
                            type="date"
                            value={editedPurchase.claim_date || ''}
                            onChange={(e) => setEditedPurchase(prev => ({
                              ...prev,
                              claim_date: e.target.value
                            }))}
                            className="mt-1 w-full bg-navy-700/50 border border-navy-600/50 rounded-lg px-3 py-2 text-sm"
                          />
                        </div>
                        <div>
                          <h3 className="text-sm font-medium text-gray-400">Claim Discount</h3>
                          <input
                            type="number"
                            value={editedPurchase.claim_discount || ''}
                            onChange={(e) => setEditedPurchase(prev => ({
                              ...prev,
                              claim_discount: e.target.value
                            }))}
                            className="mt-1 w-full bg-navy-700/50 border border-navy-600/50 rounded-lg px-3 py-2 text-sm"
                          />
                        </div>
                      </>
                    )}
                    
                    <div>
                      <h3 className="text-sm font-medium text-gray-400">After Discount</h3>
                      <input
                        type="number"
                        value={editedPurchase.after_discount || ''}
                        onChange={(e) => setEditedPurchase(prev => ({
                          ...prev,
                          after_discount: e.target.value
                        }))}
                        className="mt-1 w-full bg-navy-700/50 border border-navy-600/50 rounded-lg px-3 py-2 text-sm"
                      />
                    </div>
                    
                    <div>
                      <h3 className="text-sm font-medium text-gray-400">Sale Price</h3>
                      <input
                        type="number"
                        value={editedPurchase.sale_price || ''}
                        onChange={(e) => setEditedPurchase(prev => ({
                          ...prev,
                          sale_price: e.target.value
                        }))}
                        className="mt-1 w-full bg-navy-700/50 border border-navy-600/50 rounded-lg px-3 py-2 text-sm"
                      />
                    </div>
                    
                    <div>
                      <h3 className="text-sm font-medium text-gray-400">Total Cost to Us</h3>
                      <p className="mt-1">{formatCurrency(
                        parseFloat(editedPurchase.cost || 0) -
                        parseFloat(editedPurchase.claim_discount || 0) -
                        parseFloat(editedPurchase.after_discount || 0) -
                        parseFloat(editedPurchase.sale_price || 0)
                      )}</p>
                    </div>
                  </div>

                  {/* Add Update Button */}
                  <div className="flex justify-end mt-6">
                    <button
                      onClick={handleUpdatePurchase}
                      className={`px-4 py-2 rounded-lg text-sm font-medium ${currentTheme?.buttonBg || 'bg-purple-600'} hover:opacity-90 transition-opacity`}
                    >
                      Update Purchase
                    </button>
                  </div>

                  {/* Receipt Management */}
                  <div className="border-t border-gray-700 pt-6">
                    <h3 className="text-lg font-medium mb-4">Receipt</h3>
                    {selectedPurchase.receipt_url ? (
                      <>
                        <div className="flex items-center space-x-4 mb-4">
                          <button
                            onClick={() => downloadReceipt(selectedPurchase.receipt_url)}
                            className={`flex items-center space-x-2 px-4 py-2 rounded-lg text-sm font-medium
                              ${currentTheme?.buttonBg || 'bg-purple-600'} hover:opacity-90 transition-opacity`}
                          >
                            <PaperClipIcon className="w-5 h-5" />
                            <span>Download Receipt</span>
                          </button>
                          <label className={`flex items-center space-x-2 px-4 py-2 rounded-lg text-sm font-medium
                            bg-gray-700/50 hover:bg-gray-700 cursor-pointer transition-colors`}>
                            <input
                              type="file"
                              accept="image/*,.pdf"
                              className="hidden"
                              onChange={(e) => handleUploadReceipt(e, selectedPurchase.id)}
                              disabled={uploadingReceipt}
                            />
                            <PaperClipIcon className="w-5 h-5" />
                            <span>{uploadingReceipt ? 'Uploading...' : 'Replace Receipt'}</span>
                          </label>
                        </div>

                        {/* Receipt Preview */}
                        {previewUrl && (
                          <div className="mt-4 border border-gray-700 rounded-lg overflow-hidden">
                            {selectedPurchase.receipt_url.toLowerCase().endsWith('.pdf') ? (
                              <iframe
                                src={previewUrl}
                                className="w-full h-[400px]"
                                title="Receipt PDF"
                              />
                            ) : (
                              <div className="relative w-full h-[400px]">
                                <img
                                  src={previewUrl}
                                  alt="Receipt"
                                  className="absolute inset-0 w-full h-full object-contain bg-gray-900"
                                />
                              </div>
                            )}
                          </div>
                        )}
                      </>
                    ) : (
                      <label className={`flex items-center space-x-2 px-4 py-2 rounded-lg text-sm font-medium
                        ${currentTheme?.buttonBg || 'bg-purple-600'} hover:opacity-90 cursor-pointer transition-opacity`}>
                        <input
                          type="file"
                          accept="image/*,.pdf"
                          className="hidden"
                          onChange={(e) => handleUploadReceipt(e, selectedPurchase.id)}
                          disabled={uploadingReceipt}
                        />
                        <PaperClipIcon className="w-5 h-5" />
                        <span>{uploadingReceipt ? 'Uploading...' : 'Upload Receipt'}</span>
                      </label>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Tech;
