import React, { useState, useEffect } from 'react';
import { useAuth } from '../hooks/useAuth';
import { supabase } from '../supabase/config';
import { Line } from 'react-chartjs-2';
import Card from '../components/Card';
import CategoryAnalysis from '../components/CategoryAnalysis';
import { ArrowUpIcon, ArrowDownIcon } from '@heroicons/react/24/outline';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
} from 'chart.js';
import 'chartjs-adapter-date-fns';



// Register chart components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
);

// Set Chart.js defaults
ChartJS.defaults.color = '#fff';
ChartJS.defaults.borderColor = 'transparent';

const Dashboard = ({ currentTheme }) => {
  const { user } = useAuth();
  const [monthlySpend, setMonthlySpend] = useState([]);
  const [remainingCredit, setRemainingCredit] = useState(15000);
  const creditLimit = 15000;
  const budgetLimit = 11000;
  const [categorySpend, setCategorySpend] = useState([]);
  const [selectedYear, setSelectedYear] = useState('all');
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [categoryTransactions, setCategoryTransactions] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: 'date', direction: 'desc' });
  const [debugMessage, setDebugMessage] = useState('Initial render');

  useEffect(() => {
    setDebugMessage('Effect running');
  }, []);

  const handleRemainingCreditChange = async (event) => {
    const newRemainingCredit = parseFloat(event.target.value) || 0;
    setRemainingCredit(newRemainingCredit);

    try {
      const { error } = await supabase
        .from('shared_credit')
        .update({ 
          remaining_amount: newRemainingCredit,
          updated_at: new Date().toISOString()
        })
        .eq('id', 1);

      if (error) throw error;
    } catch (error) {
      console.error('Error updating remaining credit:', error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!user) return;

      try {
        setDebugMessage('Fetching data...');

        // Fetch monthly spend data
        const { data: monthlySpendData, error: monthlySpendError } = await supabase
          .from('monthly_spend')
          .select('*')
          .order('date', { ascending: true });

        if (monthlySpendError) throw monthlySpendError;

        const formattedMonthlySpend = monthlySpendData.map(record => ({
          ...record,
          date: new Date(record.date)
        }));
        setMonthlySpend(formattedMonthlySpend);

        // Fetch remaining credit
        const { data: creditData, error: creditError } = await supabase
          .from('shared_credit')
          .select('remaining_amount')
          .eq('id', 1)
          .single();

        if (creditError && creditError.code !== 'PGRST116') {
          console.error('Error fetching credit data:', creditError);
          return;
        }

        if (creditData) {
          setRemainingCredit(creditData.remaining_amount);
        }

      } catch (error) {
        console.error('Error in fetchData:', error);
        setDebugMessage('Error fetching data');
      }
    };

    fetchData();

    const subscription = supabase
      .channel('shared_credit_changes')
      .on(
        'postgres_changes',
        (payload) => {
          setRemainingCredit(payload.new.remaining_amount);
        }
      )
      .subscribe();

    return () => {
      subscription.unsubscribe();
    };
  }, [user]);

  const currentSpend = remainingCredit ? (creditLimit - remainingCredit) : 0;
  const budgetUsagePercent = Math.min(((currentSpend || 0) / budgetLimit) * 100, 100);

  const formatCurrency = (amount) => {
    return `$${(amount || 0).toLocaleString()}`;
  };

  const metrics = getCurrentMonthMetrics();

  function getCurrentMonthMetrics() {
    if (!monthlySpend.length) return null;

    const now = new Date();
    const currentMonth = now.getMonth();
    const currentYear = now.getFullYear();
    const lastYear = currentYear - 1;
    
    const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();
    const daysElapsed = now.getDate();

    const currentSpend = creditLimit - remainingCredit;
    const dailyRate = currentSpend / daysElapsed;
    const projectedSpend = Math.round(dailyRate * daysInMonth);
    const budgetDailyRate = budgetLimit / daysInMonth;
    
    // Calculate expected spend by current day
    const expectedSpendToDate = budgetDailyRate * daysElapsed;
    const spendDifference = currentSpend - expectedSpendToDate;

    const prevMonth = currentMonth === 0 ? 11 : currentMonth - 1;
    const prevprevMonth = currentMonth === 0 ? 10 : currentMonth - 2;
    const prevMonthYear = currentMonth === 0 ? currentYear - 1 : currentYear;
    const prevprevMonthYear = currentMonth === 0 || currentMonth === 1 ? currentYear - 1 : currentYear;
    const previousMonthSpend1 = monthlySpend.find(
      record => record.date.getMonth() === prevMonth && 
                record.date.getFullYear() === prevMonthYear
    );
    const previousMonthSpend2 = monthlySpend.find(
      record => record.date.getMonth() === prevprevMonth && 
                record.date.getFullYear() === prevprevMonthYear
    );
    const previousMonthSpend = previousMonthSpend1?.actual - previousMonthSpend2?.actual;

    const lastYearSpend1 = monthlySpend.find(
      record => record.date.getMonth() === currentMonth && 
                record.date.getFullYear() === lastYear
    )?.actual;
    const lastYearSpend2 = currentMonth === 0 ? 0 :monthlySpend.find(
      record => record.date.getMonth() === prevMonth && 
                record.date.getFullYear() === prevMonthYear
    )?.actual;
    const lastYearSpend = lastYearSpend1 - lastYearSpend2;
    
    return {
      current: projectedSpend,
      previous: previousMonthSpend || 0,
      lastYear: lastYearSpend || 0,
      budget: budgetLimit,
      daysElapsed,
      daysInMonth,
      currentSpend,
      dailyRate: Math.round(dailyRate),
      budgetDailyRate: Math.round(budgetDailyRate),
      expectedSpendToDate: Math.round(expectedSpendToDate),
      spendDifference: Math.round(spendDifference)
    };
  }

  const chartData = prepareChartData();
  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: 'linear',
        min: 1,
        max: 12,
        grid: {
          color: 'rgba(255, 255, 255, 0.1)',
        },
        ticks: { 
          color: '#9CA3AF',
          callback: function(value) {
            const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
            return months[value - 1];
          }
        }
      },
      y: {
        grid: {
          color: 'rgba(255, 255, 255, 0.1)',
        },
        ticks: {
          color: '#9CA3AF',
          callback: function(value) {
            return formatCurrency(value);
          }
        }
      }
    },
    plugins: {
      legend: {
        position: 'top',
        labels: {
          color: '#9CA3AF',
          usePointStyle: true,
          padding: 20,
          font: {
            size: 12
          }
        }
      },
      tooltip: {
        backgroundColor: 'rgba(17, 24, 39, 0.8)',
        titleColor: '#F3F4F6',
        bodyColor: '#D1D5DB',
        padding: 12,
        borderColor: 'rgba(255, 255, 255, 0.1)',
        borderWidth: 1,
        callbacks: {
          label: function(context) {
            return `${context.dataset.label}: ${formatCurrency(context.parsed.y)}`;
          }
        }
      }
    }
  };

  function prepareChartData() {
    if (!monthlySpend.length) return null;

    const yearGroups = monthlySpend.reduce((acc, record) => {
      const year = record.date.getFullYear();
      if (!acc[year]) acc[year] = [];
      acc[year].push(record);
      return acc;
    }, {});

    const currentYear = new Date().getFullYear();
    const datasets = Object.entries(yearGroups).map(([year, records]) => ({
      label: `${year} Spend`,
      data: records.map(record => ({
        x: record.date.getMonth() + 1,
        y: record.actual
      })),
      borderColor: `hsl(${parseInt(year) * 60 % 360}, 70%, 60%)`,
      backgroundColor: `hsla(${parseInt(year) * 60 % 360}, 70%, 60%, 0.1)`,
      tension: 0.4,
      fill: true
    }));
    
    const currentYearData = yearGroups[currentYear];
    if (currentYearData) {
      datasets.unshift({
        label: 'Budget Target',
        data: currentYearData.map(record => ({
          x: record.date.getMonth(),
          y: record.budget
        })),
        borderColor: '#9333EA',
        backgroundColor: 'rgba(147, 51, 234, 0.1)',
        tension: 0.4,
        fill: true,
        borderWidth: 2,
        pointRadius: 0,
        pointHoverRadius: 4,
        order: -1
      });
    }

    return { datasets };
  }

  return (
    <div className="container mx-auto px-4 py-8">

      <div className="space-y-6">
        {/* Credit Card Section */}
        <Card currentTheme={currentTheme}>
          <div className="space-y-6">
            <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-4">
              <div>
                <h2 className="text-2xl font-bold mb-1">Credit Card</h2>
                <p className="text-gray-400">Track your credit card usage and remaining balance</p>
              </div>
              <div className="relative">
                <input
                  type="number"
                  value={remainingCredit}
                  onChange={handleRemainingCreditChange}
                  className="w-full md:w-48 bg-navy-700/50 border border-navy-600/50 rounded-xl py-2 px-8 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:border-transparent"
                  placeholder="Enter remaining credit"
                />
                <div className="absolute inset-y-0 left-3 flex items-center pointer-events-none text-gray-400">
                  $
                </div>
              </div>
            </div>
            
            {/* Progress Bar */}
            <div className="space-y-2">
              <div className="flex justify-between items-center text-sm text-gray-400">
                <span>Current Spend</span>
                <span className="text-xl font-semibold text-white">{formatCurrency(currentSpend)}</span>
              </div>
              <div className="h-3 bg-navy-700/50 rounded-full overflow-hidden">
                <div
                  style={{ width: `${budgetUsagePercent}%` }}
                  className={`h-full rounded-full transition-all duration-500 ${
                    budgetUsagePercent > 90 
                      ? 'bg-red-500' 
                      : budgetUsagePercent > 75 
                        ? 'bg-yellow-500' 
                        : currentTheme?.buttonBg?.split(' ')[0] || 'bg-purple-500'
                  }`}
                />
              </div>
              <div className="flex justify-between text-sm">
                <span className="text-gray-400">Budget Limit: {formatCurrency(budgetLimit)}</span>
                <span className="text-gray-400">{formatCurrency(budgetLimit - currentSpend)} remaining</span>
              </div>
              <div className="flex justify-between text-sm">
              <span className="text-gray-400">{budgetUsagePercent.toFixed(1)}% used</span>
              </div>
            </div>
          </div>
        </Card>

        {/* Key Metrics */}
        {metrics && (
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {/* Projected Spend */}
            <Card currentTheme={currentTheme}>
              <h3 className="text-lg font-medium text-gray-200 mb-2">Projected Spend</h3>
              <div className="text-3xl font-bold text-white mb-4">
                {formatCurrency(metrics.current)}
              </div>
              <div className={`flex items-center ${metrics.current > metrics.previous ? 'text-red-400' : 'text-green-400'}`}>
                {metrics.current > metrics.previous ? (
                  <ArrowUpIcon className="w-4 h-4 mr-1" />
                ) : (
                  <ArrowDownIcon className="w-4 h-4 mr-1" />
                )}
                <span className="text-sm">
                  {Math.abs(((metrics.current - metrics.previous) / metrics.previous * 100).toFixed(1))}% vs last month
                </span>
              </div>
            </Card>

            {/* Daily Rate */}
            <Card currentTheme={currentTheme}>
              <h3 className="text-lg font-medium text-gray-200 mb-2">Daily Rate</h3>
              <div className="text-3xl font-bold text-white mb-4">
                {formatCurrency(metrics.dailyRate)}
              </div>
              <div className="space-y-2">
                <div className={`flex items-center ${
                  metrics.dailyRate > metrics.budgetDailyRate ? 'text-red-400' : 'text-green-400'
                }`}>
                  {metrics.dailyRate > metrics.budgetDailyRate ? (
                    <ArrowUpIcon className="w-4 h-4 mr-1" />
                  ) : (
                    <ArrowDownIcon className="w-4 h-4 mr-1" />
                  )}
                  <span className="text-sm">
                    {Math.abs(((metrics.dailyRate - metrics.budgetDailyRate) / metrics.budgetDailyRate * 100).toFixed(1))}% vs budget rate (${metrics.budgetDailyRate})
                  </span>
                </div>
                
                {/* Add spend progress comparison */}
                <div className={`flex items-center ${
                  metrics.spendDifference > 0 ? 'text-red-400' : 'text-green-400'
                }`}>
                  {metrics.spendDifference > 0 ? (
                    <ArrowUpIcon className="w-4 h-4 mr-1" />
                  ) : (
                    <ArrowDownIcon className="w-4 h-4 mr-1" />
                  )}
                  <span className="text-sm">
                    {Math.abs(metrics.spendDifference) > 0 ? (
                      `${formatCurrency(Math.abs(metrics.spendDifference))} ${metrics.spendDifference > 0 ? 'over' : 'under'} budget by day ${metrics.daysElapsed}`
                    ) : (
                      'On budget'
                    )}
                  </span>
                </div>
                <div className="text-xs text-gray-400">
                  Expected: {formatCurrency(metrics.expectedSpendToDate)} by day {metrics.daysElapsed}
                </div>
              </div>
            </Card>

            {/* Year Over Year */}
            <Card currentTheme={currentTheme}>
              <h3 className="text-lg font-medium text-gray-200 mb-2">vs. Last Year</h3>
              <div className="text-3xl font-bold text-white mb-4">
                {formatCurrency(metrics.current - metrics.lastYear)}
              </div>
              <div className={`flex items-center ${metrics.current > metrics.lastYear ? 'text-red-400' : 'text-green-400'}`}>
                {metrics.current > metrics.lastYear ? (
                  <ArrowUpIcon className="w-4 h-4 mr-1" />
                ) : (
                  <ArrowDownIcon className="w-4 h-4 mr-1" />
                )}
                <span className="text-sm">
                  {Math.abs(((metrics.current - metrics.lastYear) / metrics.lastYear * 100).toFixed(1))}% change
                </span>
              </div>
            </Card>
          </div>
        )}
        {/* Category Analysis */}
        <CategoryAnalysis currentTheme={currentTheme} />

        {/* Chart Section */}
        {chartData && (
          <Card currentTheme={currentTheme}>
            <h3 className="text-lg font-medium text-gray-200 mb-4">Spending Trends</h3>
            <div className="h-[300px] md:h-[400px]">
              <Line data={chartData} options={chartOptions} />
            </div>
          </Card>
        )}
      </div>
    </div>
  );
};

export default Dashboard; 