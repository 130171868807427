import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../supabase/config';

function ResetPassword() {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [hash, setHash] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Get the hash from the URL (Supabase automatically adds this)
    const hashFragment = window.location.hash;
    setHash(hashFragment);

    // Set up Supabase auth state listener
    const { data: { subscription } } = supabase.auth.onAuthStateChange(async (event, session) => {
      if (event === 'PASSWORD_RECOVERY') {
        // The token is valid
        setHash(hashFragment);
      }
    });

    return () => {
      if (subscription) subscription.unsubscribe();
    };
  }, []);

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    setError('');

    if (!hash) {
      setError("Invalid or expired reset link. Please request a new password reset.");
      return;
    }

    if (newPassword !== confirmPassword) {
      setError("Passwords don't match");
      return;
    }

    if (newPassword.length < 6) {
      setError("Password must be at least 6 characters long");
      return;
    }

    try {
      setLoading(true);
      
      // Update the user's password
      const { error } = await supabase.auth.updateUser({ password: newPassword });

      if (error) throw error;

      setSuccess(true);
      // Clear the hash from the URL
      window.location.hash = '';
      
      setTimeout(() => {
        navigate('/login');
      }, 2000);
    } catch (err) {
      if (err.message.includes('auth')) {
        setError("Invalid or expired reset link. Please request a new password reset.");
      } else {
        setError(err.message);
      }
    } finally {
      setLoading(false);
    }
  };

  // If no hash is present and not in a success state, show an error
  if (!hash && !success) {
    return (
      <div className="min-h-screen bg-[#111827] flex items-center justify-center px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full bg-[#1e293b] rounded-3xl shadow-xl p-8 space-y-8 relative overflow-hidden">
          <div className="absolute -top-20 -right-20 w-40 h-40 bg-purple-500 rounded-full opacity-20 blur-2xl"></div>
          <div className="absolute -bottom-20 -left-20 w-40 h-40 bg-purple-500 rounded-full opacity-20 blur-2xl"></div>
          
          <div className="relative z-10">
            <div className="text-center">
              <h2 className="text-3xl font-bold text-white">Invalid Reset Link</h2>
              <p className="mt-4 text-gray-400">
                This password reset link is invalid or has expired. Please return to the login page and request a new password reset.
              </p>
              <button
                onClick={() => navigate('/login')}
                className="mt-6 w-full flex justify-center py-3 px-4 rounded-xl text-white bg-purple-600 hover:bg-purple-700 transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
              >
                Return to Login
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-[#111827] flex items-center justify-center px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full bg-[#1e293b] rounded-3xl shadow-xl p-8 space-y-8 relative overflow-hidden">
        {/* Background Decoration */}
        <div className="absolute -top-20 -right-20 w-40 h-40 bg-purple-500 rounded-full opacity-20 blur-2xl"></div>
        <div className="absolute -bottom-20 -left-20 w-40 h-40 bg-purple-500 rounded-full opacity-20 blur-2xl"></div>
        
        <div className="relative z-10">
          <div className="text-center">
            <h2 className="text-3xl font-bold text-white">Reset Password</h2>
            <p className="mt-2 text-gray-400">Enter your new password below</p>
          </div>

          <form onSubmit={handlePasswordReset} className="mt-8 space-y-6">
            <div>
              <label htmlFor="new-password" className="block text-sm font-medium text-gray-300">
                New Password
              </label>
              <input
                id="new-password"
                type="password"
                required
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                className="mt-1 block w-full bg-[#2d3a4e] border border-gray-600 rounded-xl shadow-sm py-3 px-4 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                placeholder="Enter new password"
              />
            </div>

            <div>
              <label htmlFor="confirm-password" className="block text-sm font-medium text-gray-300">
                Confirm Password
              </label>
              <input
                id="confirm-password"
                type="password"
                required
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                className="mt-1 block w-full bg-[#2d3a4e] border border-gray-600 rounded-xl shadow-sm py-3 px-4 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                placeholder="Confirm new password"
              />
            </div>

            {error && (
              <div className="bg-red-900/50 border border-red-500/50 text-red-300 rounded-xl p-4 text-sm">
                {error}
              </div>
            )}

            {success && (
              <div className="bg-green-900/50 border border-green-500/50 text-green-300 rounded-xl p-4 text-sm">
                Password successfully reset! Redirecting to login...
              </div>
            )}

            <button
              type="submit"
              disabled={loading}
              className="w-full flex justify-center py-3 px-4 rounded-xl text-white bg-purple-600 hover:bg-purple-700 transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
            >
              {loading ? 'Resetting...' : 'Reset Password'}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword; 