import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { supabase } from '../supabase/config';
import Card from '../components/Card';
import FileUpload from '../components/FileUpload';

const Profile = ({ currentTheme }) => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();
  const { user } = useAuth();
  const [selectedTheme, setSelectedTheme] = React.useState('purple');
  const [debugMessage, setDebugMessage] = useState('Initial render');

  React.useEffect(() => {
    const fetchUserTheme = async () => {
      try {
        const { data, error } = await supabase
          .from('user_preferences')
          .select('theme')
          .eq('user_id', user.id)
          .single();

        if (error) throw error;
        if (data) {
          setSelectedTheme(data.theme);
        }
      } catch (err) {
        console.error('Error fetching user theme:', err);
      }
    };

    fetchUserTheme();
  }, [user.id]);

  const handleThemeChange = async (themeId) => {
    try {
      const { error } = await supabase
        .from('user_preferences')
        .upsert({
          user_id: user.id,
          theme: themeId,
          updated_at: new Date().toISOString()
        }, {
          onConflict: 'user_id'
        });

      if (error) throw error;

      setSelectedTheme(themeId);
      window.dispatchEvent(new CustomEvent('themeChange', { detail: themeId }));
    } catch (err) {
      console.error('Error updating theme:', err);
    }
  };

  const [APIUsage, setRemainingAPIUsage] = useState(500);
  const handleRemainingAPIUsageChange = async (event) => {
  const newRemainingAPIUsage = parseFloat(event.target.value) || 0;
  setRemainingAPIUsage(newRemainingAPIUsage);

  try {
    const { error } = await supabase
      .from('shared_credit')
      .update({ 
        remaining_api: newRemainingAPIUsage,
        updated_at: new Date().toISOString()
      })
      .eq('id', 1);

    if (error) throw error;
  } catch (error) {
    console.error('Error updating remaining API usage:', error);
  }
};


const metrics = getCurrentMonthMetrics();

  function getCurrentMonthMetrics() {

    const now = new Date();
    const currentMonth = now.getMonth();
    const currentYear = now.getFullYear();
    const daysinlastmonth = new Date(currentYear, currentMonth + 1, 0).getDate();
    const daysinthismonth = new Date(currentYear, currentMonth, 0).getDate();
    const daysElapsed = now.getDate();
    const twentiethDayOfMonth = daysElapsed > 20 ? daysElapsed - 20 : daysElapsed + daysinlastmonth - 20;
    
    
    const APILimit = (500 / daysinthismonth * twentiethDayOfMonth).toFixed(0);
    const budgetAPIUsagePercent = (APIUsage / APILimit) * 100;
      
    return {
      APILimit,
      budgetAPIUsagePercent
    };
  }

React.useEffect(() => {
const fetchData = async () => {
  if (!user) return;

  try {
    setDebugMessage('Fetching data...');

    // Fetch remaining credit
    const { data: creditData, error: creditError } = await supabase
      .from('shared_credit')
      .select('remaining_api')
      .eq('id', 1)
      .single();

    if (creditError && creditError.code !== 'PGRST116') {
      console.error('Error fetching credit data:', creditError);
      return;
    }

    if (creditData) {
      setRemainingAPIUsage(creditData.remaining_api);
    }

  } catch (error) {
    console.error('Error in fetchData:', error);
    setDebugMessage('Error fetching data');
  }
};

fetchData();
},[user]);



  const handleLogout = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      navigate('/login');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const handlePasswordReset = async (e) => {
    
    e.preventDefault();
    setError('');

    if (newPassword !== confirmPassword) {
      setError("Passwords don't match");
      return;
    }

    if (newPassword.length < 6) {
      setError("Password must be at least 6 characters long");
      return;
    }

    try {
      setLoading(true);
      
      // Update the user's password
      const { error } = await supabase.auth.updateUser({ password: newPassword });

      if (error) throw error;

      setSuccess(true);
      // Clear the hash from the URL
      window.location.hash = '';
      
      setTimeout(() => {
        navigate('/');
      }, 2000);
    } catch (err) {
      if (err.message.includes('auth')) {
        setError("Invalid or expired reset link. Please request a new password reset.");
      } else {
        setError(err.message);
      }
    } finally {
      setLoading(false);
    }
  };


  return (
    <div className="container mx-auto px-4 py-8">
      <div className="space-y-6">
        {/* API Usage Section */}
        <Card>
          <div className="space-y-6">
            <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-4">
              <div>
                <h2 className="text-2xl font-bold mb-1">API Usage</h2>
                <p className="text-gray-400">Track your API usage and remaining balance</p>
              </div>
              <div className="relative">
                <input
                  type="number"
                  value={APIUsage}
                  onChange={handleRemainingAPIUsageChange}
                  className="w-full md:w-48 bg-navy-700/50 border border-navy-600/50 rounded-xl py-2 px-8 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                  placeholder="Enter API usage"
                />
              </div>
            </div>
            
            {/* Progress Bar */}
            {metrics && (
            <div className="space-y-2">
              <div className="flex justify-between items-center text-sm text-gray-400">
                <span>Current Spend</span>
                <span className="text-xl font-semibold text-white">{APIUsage}</span>
              </div>
              <div className="h-3 bg-navy-700/50 rounded-full overflow-hidden">
                <div
                  style={{ width: `${metrics.budgetAPIUsagePercent}%` }}
                  className={`h-full rounded-full transition-all duration-500 ${
                    metrics.budgetAPIUsagePercent > 90 
                      ? 'bg-red-500' 
                      : metrics.budgetAPIUsagePercent > 75 
                        ? 'bg-yellow-500' 
                        : currentTheme?.buttonBg?.split(' ')[0] || 'bg-purple-500'
                  }`}
                />
              </div>
              <div className="flex justify-between text-sm">
                <span className="text-gray-400">API Limit: {metrics.APILimit}</span>
                <span className="text-gray-400">{metrics.APILimit - APIUsage} remaining</span>
              </div>
              <div className="flex justify-between text-sm">
              <span className="text-gray-400">{metrics.budgetAPIUsagePercent.toFixed(1)}% used</span>
              </div>
            </div>
            )}
          </div>
        </Card>

        {/* File Upload */}
        <Card>
          <h2 className="text-2xl font-bold mb-6">Upload Files</h2>
          <div className="space-y-4">
            <FileUpload type="credit" />
            <FileUpload type="monthly" />
            <FileUpload type="tech" />
          </div>
        </Card>

        {/* Reset Password */}
        <div className="min-h-screen bg-[#111827] flex items-center justify-center px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full bg-[#1e293b] rounded-3xl shadow-xl p-8 space-y-8 relative overflow-hidden">

        <div className="relative z-10">
          <div className="text-center">
            <h2 className="text-3xl font-bold text-white">Reset Password</h2>
            <p className="mt-2 text-gray-400">Enter your new password below</p>
          </div>

          <form onSubmit={handlePasswordReset} className="mt-8 space-y-6">
            <div>
              <label htmlFor="new-password" className="block text-sm font-medium text-gray-300">
                New Password
              </label>
              <input
                id="new-password"
                type="password"
                required
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                className="mt-1 block w-full bg-[#2d3a4e] border border-gray-600 rounded-xl shadow-sm py-3 px-4 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                placeholder="Enter new password"
              />
            </div>

            <div>
              <label htmlFor="confirm-password" className="block text-sm font-medium text-gray-300">
                Confirm Password
              </label>
              <input
                id="confirm-password"
                type="password"
                required
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                className="mt-1 block w-full bg-[#2d3a4e] border border-gray-600 rounded-xl shadow-sm py-3 px-4 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                placeholder="Confirm new password"
              />
            </div>

            {error && (
              <div className="bg-red-900/50 border border-red-500/50 text-red-300 rounded-xl p-4 text-sm">
                {error}
              </div>
            )}

            {success && (
              <div className="bg-green-900/50 border border-green-500/50 text-green-300 rounded-xl p-4 text-sm">
                Password successfully reset! Redirecting to login...
              </div>
            )}

            <button
              type="submit"
              disabled={loading}
              className="w-full flex justify-center py-3 px-4 rounded-xl text-white bg-teal-600 hover:bg-teal-700 transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
            >
              {loading ? 'Resetting...' : 'Reset Password'}
            </button>
          </form>
        </div>
      </div>
    </div>

        {/* Logout */}
        <Card>
          <button
            onClick={handleLogout}
            className="w-full px-6 py-3 bg-red-500/10 text-red-500 rounded-xl hover:bg-red-500/20 transition-colors focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 focus:ring-offset-[#1e293b] flex items-center justify-center space-x-2"
          >
            <svg 
              className="w-5 h-5" 
              fill="none" 
              stroke="currentColor" 
              viewBox="0 0 24 24"
            >
              <path 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                strokeWidth={2} 
                d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
              />
            </svg>
            <span>Logout</span>
          </button>
        </Card>
      </div>
    </div>
  );
}

export default Profile; 