import React, { useState, useEffect, useMemo } from 'react';
import { useAuth } from '../hooks/useAuth';
import { supabase } from '../supabase/config';
import { PlusIcon, PencilIcon, TrashIcon } from '@heroicons/react/24/outline';
import '../styles/flipClock.css';

// FlipCard component for individual digits
const FlipCard = ({ digit, prevDigit, shouldAnimate }) => {
  const [isFlipping, setIsFlipping] = useState(false);
  const [currentDigit, setCurrentDigit] = useState('0');
  const [initialAnimation, setInitialAnimation] = useState(true);

  useEffect(() => {
    if (shouldAnimate && initialAnimation) {
      let count = 0;
      const interval = setInterval(() => {
        if (count <= parseInt(digit)) {
          setCurrentDigit(count.toString());
          count++;
        } else {
          clearInterval(interval);
          setInitialAnimation(false);
        }
      }, 50);
      return () => clearInterval(interval);
    }
  }, [digit, shouldAnimate]);

  useEffect(() => {
    if (!initialAnimation && prevDigit !== digit) {
      setIsFlipping(true);
      const timer = setTimeout(() => {
        setIsFlipping(false);
      }, 600);
      return () => clearTimeout(timer);
    }
  }, [digit, prevDigit, initialAnimation]);

  const displayDigit = initialAnimation ? currentDigit : digit;
  const displayPrevDigit = initialAnimation ? (parseInt(currentDigit) - 1).toString() : prevDigit;

  return (
    <div className={`flip-card ${isFlipping ? 'flipping' : ''}`}>
      <div className="top">
        <span className="digit">{displayPrevDigit}</span>
      </div>
      <div className="bottom">
        <span className="digit">{displayDigit}</span>
      </div>
      <div className="back-top">
        <span className="digit">{displayDigit}</span>
      </div>
      <div className="back-bottom">
        <span className="digit">{displayPrevDigit}</span>
      </div>
    </div>
  );
};

// TimeUnit component to display a pair of digits with label
const TimeUnit = ({ value, label, prevValue, shouldAnimate }) => {
  const digits = value.toString().padStart(2, '0').split('');
  const prevDigits = (prevValue || 0).toString().padStart(2, '0').split('');
  
  return (
    <div className="countdown-unit">
      <div className="flex gap-1">
        <FlipCard 
          digit={digits[0]} 
          prevDigit={prevDigits[0]} 
          shouldAnimate={shouldAnimate} 
        />
        <FlipCard 
          digit={digits[1]} 
          prevDigit={prevDigits[1]} 
          shouldAnimate={shouldAnimate} 
        />
      </div>
      <div className="time-label">
        {label}
      </div>
    </div>
  );
};

const Countdown = ({ currentTheme }) => {
  const { user } = useAuth();
  const [countdowns, setCountdowns] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editingCountdown, setEditingCountdown] = useState(null);
  const [newCountdown, setNewCountdown] = useState({
    name: '',
    targetDate: ''
  });
  const [prevTimeLeft, setPrevTimeLeft] = useState({});
  const [timeLeftValues, setTimeLeftValues] = useState({});
  const [shouldAnimate, setShouldAnimate] = useState(false);

  const fetchCountdowns = async () => {
    if (!user) return;

    try {
      const { data, error } = await supabase
        .from('countdowns')
        .select('*')
        .order('target_date', { ascending: true });

      if (error) throw error;
      setCountdowns(data || []);
      setTimeout(() => setShouldAnimate(true), 500);
    } catch (error) {
      console.error('Error fetching countdowns:', error);
    }
  };

  const handleDelete = async (id) => {
    try {
      const { error } = await supabase
        .from('countdowns')
        .delete()
        .eq('id', id);

      if (error) throw error;
      fetchCountdowns();
    } catch (error) {
      console.error('Error deleting countdown:', error);
    }
  };

  const handleEdit = (countdown) => {
    setEditingCountdown(countdown);
    setShowEditModal(true);
  };

  const handleUpdate = async () => {
    try {
      const { error } = await supabase
        .from('countdowns')
        .update({
          name: editingCountdown.name,
          target_date: new Date(editingCountdown.targetDate).toISOString()
        })
        .eq('id', editingCountdown.id);

      if (error) throw error;
      setShowEditModal(false);
      setEditingCountdown(null);
      fetchCountdowns();
    } catch (error) {
      console.error('Error updating countdown:', error);
    }
  };

  // Sort countdowns with expired ones at the end
  const sortedCountdowns = useMemo(() => {
    return [...countdowns].sort((a, b) => {
      const aExpired = new Date(a.target_date) < new Date();
      const bExpired = new Date(b.target_date) < new Date();
      if (aExpired && !bExpired) return 1;
      if (!aExpired && bExpired) return -1;
      return new Date(a.target_date) - new Date(b.target_date);
    });
  }, [countdowns]);

  useEffect(() => {
    fetchCountdowns();
    return () => setShouldAnimate(false);
  }, [user]);

  useEffect(() => {
    const interval = setInterval(() => {
      const newTimeLeft = {};
      countdowns.forEach(countdown => {
        newTimeLeft[countdown.id] = calculateTimeLeft(countdown.target_date);
      });
      setTimeLeftValues(prev => {
        setPrevTimeLeft(prev);
        return newTimeLeft;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [countdowns]);

  const calculateTimeLeft = (targetDate) => {
    const difference = new Date(targetDate) - new Date();
    
    if (difference <= 0) {
      return {
        months: 0,
        days: 0,
        hours: 0
      };
    }

    const months = Math.floor(difference / (1000 * 60 * 60 * 24 * 30));
    const days = Math.floor((difference / (1000 * 60 * 60 * 24)) % 30);
    const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);

    return {
      months,
      days,
      hours
    };
  };

  return (
    <div className="container mx-auto px-4 py-8 pb-32">
      {/* Countdowns Grid */}
      <div className="countdown-grid">
        {sortedCountdowns.map((countdown) => {
          const timeLeft = timeLeftValues[countdown.id] || calculateTimeLeft(countdown.target_date);
          const prev = prevTimeLeft[countdown.id] || timeLeft;
          const isExpired = new Date(countdown.target_date) < new Date();
          
          return (
            <div 
              key={countdown.id} 
              className={`countdown-card relative ${
                isExpired ? 'bg-gradient-to-br from-red-900/50 to-red-950/50 border border-red-900/50' : ''
              }`}
            >
              <div className="flex justify-between items-center mb-4">
                <h3 className={`${isExpired ? 'text-red-400' : 'text-white'} text-xl font-semibold`}>
                  {countdown.name}
                </h3>
                <div className="flex gap-2">
                  <button
                    onClick={() => handleEdit(countdown)}
                    className="p-2 rounded-lg hover:bg-gray-700/50 transition-colors"
                  >
                    <PencilIcon className="w-4 h-4 text-gray-400 hover:text-white transition-colors" />
                  </button>
                  <button
                    onClick={() => handleDelete(countdown.id)}
                    className="p-2 rounded-lg hover:bg-gray-700/50 transition-colors"
                  >
                    <TrashIcon className="w-4 h-4 text-gray-400 hover:text-white transition-colors" />
                  </button>
                </div>
              </div>
              <div className="countdown-units">
                <TimeUnit 
                  value={timeLeft.months} 
                  label="Months" 
                  prevValue={prev.months}
                  shouldAnimate={shouldAnimate}
                />
                <TimeUnit 
                  value={timeLeft.days} 
                  label="Days" 
                  prevValue={prev.days}
                  shouldAnimate={shouldAnimate}
                />
                <TimeUnit 
                  value={timeLeft.hours} 
                  label="Hours" 
                  prevValue={prev.hours}
                  shouldAnimate={shouldAnimate}
                />
              </div>
              {isExpired && (
                <div className="absolute top-2 right-2 text-xs font-medium text-red-400">
                  Expired
                </div>
              )}
            </div>
          );
        })}
      </div>

      {/* Add Countdown Modal */}
      {showAddModal && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4">
          <div className="bg-gray-900 rounded-xl p-6 w-full max-w-md">
            <h2 className="text-xl font-semibold mb-4 text-white">Add New Countdown</h2>
            
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-400 mb-1">
                  Event Name
                </label>
                <input
                  type="text"
                  value={newCountdown.name}
                  onChange={(e) => setNewCountdown(prev => ({ ...prev, name: e.target.value }))}
                  className="w-full bg-gray-800 border border-gray-700 rounded-lg px-4 py-2 text-white"
                  placeholder="Enter event name"
                />
              </div>
              
              <div>
                <label className="block text-sm font-medium text-gray-400 mb-1">
                  Target Date
                </label>
                <input
                  type="datetime-local"
                  value={newCountdown.targetDate}
                  onChange={(e) => setNewCountdown(prev => ({ ...prev, targetDate: e.target.value }))}
                  className="w-full bg-gray-800 border border-gray-700 rounded-lg px-4 py-2 text-white"
                />
              </div>
            </div>

            <div className="flex justify-end gap-3 mt-6">
              <button
                onClick={() => setShowAddModal(false)}
                className="px-4 py-2 rounded-lg bg-gray-800 text-white hover:bg-gray-700 transition-colors"
              >
                Cancel
              </button>
              <button
                onClick={async () => {
                  try {
                    const { error } = await supabase
                      .from('countdowns')
                      .insert([{
                        user_id: user.id,
                        name: newCountdown.name,
                        target_date: new Date(newCountdown.targetDate).toISOString()
                      }]);

                    if (error) throw error;
                    
                    setShowAddModal(false);
                    setNewCountdown({ name: '', targetDate: '' });
                    fetchCountdowns();
                  } catch (error) {
                    console.error('Error adding countdown:', error);
                  }
                }}
                className="px-4 py-2 rounded-lg bg-salmon text-white hover:bg-salmon/90 transition-colors"
              >
                Add Countdown
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Edit Countdown Modal */}
      {showEditModal && editingCountdown && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4">
          <div className="bg-gray-900 rounded-xl p-6 w-full max-w-md">
            <h2 className="text-xl font-semibold mb-4 text-white">Edit Countdown</h2>
            
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-400 mb-1">
                  Event Name
                </label>
                <input
                  type="text"
                  value={editingCountdown.name}
                  onChange={(e) => setEditingCountdown(prev => ({ ...prev, name: e.target.value }))}
                  className="w-full bg-gray-800 border border-gray-700 rounded-lg px-4 py-2 text-white"
                  placeholder="Enter event name"
                />
              </div>
              
              <div>
                <label className="block text-sm font-medium text-gray-400 mb-1">
                  Target Date
                </label>
                <input
                  type="datetime-local"
                  value={editingCountdown.targetDate || new Date(editingCountdown.target_date).toISOString().slice(0, 16)}
                  onChange={(e) => setEditingCountdown(prev => ({ ...prev, targetDate: e.target.value }))}
                  className="w-full bg-gray-800 border border-gray-700 rounded-lg px-4 py-2 text-white"
                />
              </div>
            </div>

            <div className="flex justify-end gap-3 mt-6">
              <button
                onClick={() => {
                  setShowEditModal(false);
                  setEditingCountdown(null);
                }}
                className="px-4 py-2 rounded-lg bg-gray-800 text-white hover:bg-gray-700 transition-colors"
              >
                Cancel
              </button>
              <button
                onClick={handleUpdate}
                className="px-4 py-2 rounded-lg bg-salmon text-white hover:bg-salmon/90 transition-colors"
              >
                Update Countdown
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Floating Action Button */}
      <button
        onClick={() => setShowAddModal(true)}
        className="fixed bottom-24 right-6 p-4 rounded-full bg-gradient-to-br from-salmon/20 to-salmon/30 shadow-lg hover:from-salmon/30 hover:to-salmon/40 transition-all"
        style={{
          boxShadow: '0 0 20px rgba(250, 128, 114, 0.3), 0 4px 6px -1px rgba(0, 0, 0, 0.1)',
          backdropFilter: 'blur(8px)',
          WebkitBackdropFilter: 'blur(8px)',
        }}
      >
        <div className="bg-salmon rounded-full p-2">
          <PlusIcon className="w-6 h-6 text-white" />
        </div>
      </button>
    </div>
  );
};

export default Countdown; 