import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { useAuth, AuthProvider } from './hooks/useAuth';
import { supabase } from './supabase/config';
import Navbar from './components/Navbar';
import Dashboard from './pages/Dashboard';
import Transactions from './pages/Transactions';
import Login from './pages/Login';
import Theme from './pages/Theme';
import Signup from './pages/Signup';
import ResetPassword from './pages/ResetPassword';
import InvestmentsPage from './pages/Investments';
import PrivateRoute from './components/PrivateRoute';
import Tech from './pages/Tech';
import Countdown from './pages/Countdown';
import Profile from './pages/Profile';

const themes = {
  purple: {
    gradient: 'from-teal-600 via-teal-900 to-black',
    buttonBg: 'bg-teal-600 hover:bg-teal-700',
    accent: 'bg-teal-500/30'
  },
  green: {
    gradient: 'from-green-600 via-green-900 to-black',
    buttonBg: 'bg-green-600 hover:bg-green-700',
    accent: 'bg-green-500/30'
  },
  blue: {
    gradient: 'from-blue-600 via-blue-900 to-black',
    buttonBg: 'bg-blue-600 hover:bg-blue-700',
    accent: 'bg-blue-500/30'
  },
  sky: {
    gradient: 'from-sky-600 via-sky-900 to-black',
    buttonBg: 'bg-sky-600 hover:bg-sky-700',
    accent: 'bg-sky-500/30'
  }
};

// Protected Route component
function ProtectedRoute({ children }) {
  const { user, loading } = useAuth();
  const location = useLocation();

  if (loading) {
    return (
      <div className="min-h-screen bg-[#111827] flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-purple-500"></div>
      </div>
    );
  }

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

// Public Route component (only accessible when logged out)
function PublicRoute({ children }) {
  const { user, loading } = useAuth();
  const location = useLocation();

  if (loading) {
    return (
      <div className="min-h-screen bg-[#111827] flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-purple-500"></div>
      </div>
    );
  }

  if (user) {
    return <Navigate to={location.state?.from?.pathname || "/"} replace />;
  }

  return children;
}

// Wrapper component to handle navbar visibility and theme
function AppContent() {
  const location = useLocation();
  const { user } = useAuth();
  const [currentTheme, setCurrentTheme] = useState('purple');
  const isAuthPage = ['/login', '/signup', '/reset-password'].includes(location.pathname);

  useEffect(() => {
    const fetchUserTheme = async () => {
      if (!user) return;

      try {
        const { data, error } = await supabase
          .from('user_preferences')
          .select('theme')
          .eq('user_id', user.id)
          .single();

        if (error) throw error;
        if (data) {
          setCurrentTheme(data.theme);
        }
      } catch (error) {
        console.error('Error fetching user theme:', error);
      }
    };

    fetchUserTheme();

    // Listen for theme changes
    const handleThemeChange = (event) => {
      setCurrentTheme(event.detail);
    };

    window.addEventListener('themeChange', handleThemeChange);
    return () => window.removeEventListener('themeChange', handleThemeChange);
  }, [user]);

  return (
    <>
      {!isAuthPage && <Navbar currentTheme={themes[currentTheme]} />}
      <div className="min-h-screen bg-navy-900 text-white pb-[calc(4rem+env(safe-area-inset-bottom))]">
        <Routes>
          <Route path="/login" element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          } />
          <Route path="/signup" element={
            <PublicRoute>
              <Signup />
            </PublicRoute>
          } />
          <Route path="/reset-password" element={
            <PublicRoute>
              <ResetPassword />
            </PublicRoute>
          } />
          <Route path="/" element={
            <ProtectedRoute>
              <Dashboard currentTheme={themes[currentTheme]} />
            </ProtectedRoute>
          } />
          <Route 
            path="/tech" 
            element={
              <ProtectedRoute>
                <Tech currentTheme={themes[currentTheme]} />
              </ProtectedRoute>
            } 
          />
          <Route path="/theme" element={
            <ProtectedRoute>
              <Theme currentTheme={themes[currentTheme]} />
            </ProtectedRoute>
          } />
          <Route 
            path="/investments" 
            element={
              <PrivateRoute>
                <InvestmentsPage />
              </PrivateRoute>
            } 
          />
          <Route 
            path="/countdown" 
            element={
              <ProtectedRoute>
                <Countdown currentTheme={themes[currentTheme]} />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/profile" 
            element={
              <PrivateRoute>
                <Profile />
              </PrivateRoute>
            } 
          />
        </Routes>
      </div>
    </>
  );
}

function App() {
  return (
    <div className="bg-navy-900" style={{ paddingTop: 40}}>
      <Router>
      <AuthProvider>
        <AppContent />
      </AuthProvider>
    </Router>
    </div>
      
    
  );
}

export default App; 