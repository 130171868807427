import React, { useState, useEffect } from 'react';
import { useAuth } from '../hooks/useAuth';
import { supabase } from '../supabase/config';

const themes = [
  {
    name: 'Purple',
    id: 'purple',
    gradient: 'from-cyan-500 via-cyan-500 to-black',
    buttonBg: 'bg-cyan-500  hover:bg-cyan-700',
    accent: 'bg-cyan-500/30',
    preview: 'bg-gradient-to-r from-cyan-600 via-cyan-900 to-black'
  },
  {
    name: 'Green',
    id: 'green',
    gradient: 'from-green-600 via-green-900 to-black',
    buttonBg: 'bg-green-600 hover:bg-green-700',
    accent: 'bg-green-500/30',
    preview: 'bg-gradient-to-r from-green-600 via-green-900 to-black'
  },
  {
    name: 'Blue',
    id: 'blue',
    gradient: 'from-blue-600 via-blue-900 to-black',
    buttonBg: 'bg-blue-600 hover:bg-blue-700',
    accent: 'bg-blue-500/30',
    preview: 'bg-gradient-to-r from-blue-600 via-blue-900 to-black'
  },
  {
    name: 'Sky',
    id: 'sky',
    gradient: 'from-sky-600 via-sky-900 to-black',
    buttonBg: 'bg-sky-600 hover:bg-sky-700',
    accent: 'bg-sky-500/30',
    preview: 'bg-gradient-to-r from-sky-600 via-sky-900 to-black'
  }
];

function Theme() {
  const { user } = useAuth();
  const [selectedTheme, setSelectedTheme] = useState('purple');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchUserTheme = async () => {
      if (!user) return;

      try {
        const { data, error } = await supabase
          .from('user_preferences')
          .select('theme')
          .eq('user_id', user.id)
          .single();

        if (error) throw error;
        if (data) {
          setSelectedTheme(data.theme);
        }
      } catch (error) {
        console.error('Error fetching user theme:', error);
      }
    };

    fetchUserTheme();
  }, [user]);

  const handleThemeChange = async (themeId) => {
    if (!user || loading) return;

    setLoading(true);
    try {
      const { error } = await supabase
        .from('user_preferences')
        .upsert({
          user_id: user.id,
          theme: themeId
        });

      if (error) throw error;

      setSelectedTheme(themeId);
      // Dispatch theme change event for other components
      window.dispatchEvent(new CustomEvent('themeChange', { detail: themeId }));
    } catch (error) {
      console.error('Error saving theme preference:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="bg-[#1e293b] rounded-3xl shadow-xl p-8 text-white relative overflow-hidden">
        <div className="relative z-10">
          <h2 className="text-2xl font-bold mb-6">Theme Settings</h2>
          
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
            {themes.map((theme) => (
              <div 
                key={theme.id}
                className={`
                  relative rounded-2xl overflow-hidden cursor-pointer
                  transition-transform hover:scale-105
                  ${selectedTheme === theme.id ? 'ring-2 ring-white ring-offset-4 ring-offset-[#1e293b]' : ''}
                `}
                onClick={() => handleThemeChange(theme.id)}
              >
                {/* Preview */}
                <div className={`h-32 ${theme.preview}`}></div>
                
                {/* Theme Info */}
                <div className="absolute bottom-0 left-0 right-0 p-4 bg-black/50 backdrop-blur-sm">
                  <div className="flex items-center justify-between">
                    <span className="font-medium">{theme.name}</span>
                    {selectedTheme === theme.id && (
                      <svg 
                        className="w-5 h-5 text-white" 
                        fill="none" 
                        stroke="currentColor" 
                        viewBox="0 0 24 24"
                      >
                        <path 
                          strokeLinecap="round" 
                          strokeLinejoin="round" 
                          strokeWidth={2} 
                          d="M5 13l4 4L19 7" 
                        />
                      </svg>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>

          {loading && (
            <div className="mt-4 text-center text-gray-400">
              Saving your preference...
            </div>
          )}
        </div>

        {/* Background Decoration */}
        <div className="absolute -top-20 -right-20 w-40 h-40 bg-purple-500 rounded-full opacity-20 blur-2xl"></div>
        <div className="absolute -bottom-20 -left-20 w-40 h-40 bg-purple-500 rounded-full opacity-20 blur-2xl"></div>
      </div>
    </div>
  );
}

export default Theme; 